// palette
$main-background-color: rgb(232, 236, 236);
$secondary-background-color: #ecd8bf;
$main-color: #023c40;
$secondary-color: #683d00;

$secondary: #333;

* {
  padding: 0;
  margin:0;
  box-sizing: border-box;
  // background-color: $main-background-color;
  //color: $secondary;
}

.secondaryColor {
  color: $secondary
}

// position
@mixin column-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin column-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

@mixin column-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

@mixin row-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin row-left {
  display: flex;
  justify-content: center;
  align-items: center;
}


// todo moveme
.timeline-container {
  //position: absolute;
  //margin: 100px;
  border: 1px solid #000;

  > .container {
    //position: relative;
  }

  //width: 80vw;
  //height: 10px;
}

.timeline-canvas {

  //padding: 10px;

  canvas {
    height: 100vh;
    width: 100vw;
  }
}

//.connection-modal {
//  min-width: 80vw;
//  max-width: 90vw;
//  @media only screen and (min-device-width: 768px){
//    min-width: 70vw;
//    max-width: 90vw;
//  }
//}


.info-modal {
  z-index: 99;
}

.settings-modal {
  z-index: 99;

  // currently in material ui
  //min-width: 80vw;
  //max-width: 90vw;
  //@media only screen and (min-device-width: 768px){
  //  min-width: 70vw;
  //  max-width: 90vw;
  //}


  img {
    //padding: 10vw;
    width: 100%;
    height: 100%;
    padding: 5px;
    //max-height: 50vh;
  }
}

.App {
  min-height: 100vh;
}



.text1-container {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 5px;
}

.text1 {
  color: white;

}

.text2-container {
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 0;
  padding: 5px;

}
.text2 {
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
}

.text3-container {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 5px;

}
.text4-container {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

}
.text3 {
  //color: white;
  //a {
  //  color: white;
  //  text-decoration: none;
  //}
}

.text4 {
  display:flex;
}


.pointer {
  &:hover {
    cursor: pointer;
  }
}

.underlined {
  text-decoration: underline;
}

// todo still seems to be a height issue on mobile (click logo at bottom and scroll

.audioURLInput {
  width: 80%;
}
.addressInput {
  width: 80%;
}

.spinner {
  height: 15px;
  width: 15px;
}

.closeModalButton {
  cursor: pointer;
  position: absolute !important;
  right: 5px !important;
  top:5px !important;
}

.error {
  color: red;
}

.minitext {
  font-size: 10px;
}

.secondaryColor {
  color: $secondary;
}

.connectButtonContainer {
  display: flex;
  flex-direction: row;
}

.modalTitle {
  color: $secondary;
}

.connectTypeButton {
  margin-right: 5px !important;
}

.sourceLogo, .displayModeLogo, .sceneLogo {
  display: flex;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;

  &--active {
    //background-color: black;
    border: 2px solid black;
  }
}

.sourceLogo-container {
  display: flex;
  flex-direction: column;
}
.sourceLogo-container2 {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.sourcesBox {
  display: flex;
  flex-direction: row;
}

.scenesBox {
  display: flex;
  flex-direction: row;
}

.displayModeBox {
  display: flex;
  flex-direction: row;
}

.settings-according {
  display: flex !important;
  flex-direction: column !important;
  left: 0 !important;
  position: absolute !important;
  width: 100% !important;

  //box-shadow: none !important;
  //background-color: 'white' !important;

  .MuiAccordionDetails-root {
    flex-direction: column;
  }
  &--summary {
    //display: flex !important;
    //flex-direction: column !important;
    //padding: 0 !important;
  }
}


.shareLinkBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shareLinkLabel {
  //margin-bottom: 5px !important;
  flex-grow: 1;
  margin-right: 15px;
}

.generatedShareLink {
  overflow: scroll;
  line-break: anywhere;
  color: blue;
  cursor: pointer;
}

.fixedSettingsTag {
  position: sticky;
  top:0;
  left: 0;
  background-color: white;
  padding-top: 15px;
  z-index: 10;
}

.closeModalButtonOk {
  margin-top: 15px !important;
}